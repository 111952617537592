// xs = max-width: 575.98px
// sm = max-width: 767.98px
// md = max-width: 991.98px
// lg = max-width: 1199.98px

@include media-breakpoint-between(sm, md) {
    .freeSlotsCalendarContainer {
    .freeSlotsCalendar {
      .react-calendar {
        .today {
          abbr {
            padding: 5px;
          }
        }
        .react-calendar__tile--active:not(.disable) {
          abbr {
            padding: 5px;
          }
        }
      }
    }
  }
  .freeSlotsContainer {
    .fsContent {
      .freeSlotBox {
        .freeSlotsItem {
          padding: 8px;
        }
      }
    }
  }
}
@include media-breakpoint-down(xxl) {
  .home {
    .appButtons {
      top: 340px;
      left: 233px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .freeSlotsContainer {
    .fsContent {
      margin-left: 0;
    }
  }
  .home{
    .appButtons {
      top: 350px;
      left: 92px;
      a{
        height: 43px;
        padding: 8px;
      }
    }
    .appSnapshot {
      .applicationSnapshot {
        height: 450px;
        width: 340px;
        left: -105px;
        top: 30px;
        background-position: -140px;
        background-size: 187%;
        background-repeat: no-repeat;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .home{
    .appButtons {
      left: 96px;
      top: 345px;
      a{
        height: 40px;
        padding: 8px
      }
    }
    .appSnapshot {
      .applicationSnapshot {
        height: 450px;
        width: 340px;
        left: -105px;
        top: 30px;
        background-position: -140px;
        background-size: 187%;
        background-repeat: no-repeat;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .home{
    .appButtons {
      left: 96px;
      top: 345px;
      a{
        height: 40px;
        padding: 8px
      }
    }
    .appSnapshot {
      .applicationSnapshot {
        height: 450px;
        width: 340px;
        left: -105px;
        top: 30px;
        background-position: -140px;
        background-size: 187%;
        background-repeat: no-repeat;
      }
    }

  }

  .backButton {
    left: 0;
  }

  .alert_container {
    margin-left: -145px;
    width: 500px;
  }

  .header {
    .separator {
      &::after {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .home{
    .appButtons {
      left: 62px;
      top: 275px;
      a{
        height: 33px;
        padding: 5px
      }
    }
    .appSnapshot {
      .applicationSnapshot {
        height: 366px;
        width: 250px;
        left: -80px;
        top: 30px;
        background-position: -105px;
        background-size: 187%;
        background-repeat: no-repeat;
      }
    }

    h1 {
      font-size: 40px;
      line-height: 40px;
      &.homeBannerBigFont {
        font-size: 55px;
        line-height: 55px;
      }
    }
  }
  .btn-black {
    width: 75px;
    height: 30px;
    margin-bottom: 10px;
  }
  .appointersList .appointersListItem {
    .appointersListItemDetails {
      .appointersListItemName {
        padding-bottom: 0;

        span {
          display: inline-block;
        }

        .appointersListItemAddress {
          float: none;
          position: relative;
          top: 0;
          line-height: 16px;
        }
      }
    }
  }

  .appointer {
    margin-left: 0;
    margin-right: 0;
  }

  .freeSlotsCalendarContainer {
    .freeSlotsCalendar {
      .react-calendar {
        .today {
          abbr {
            padding: 3px;
          }
        }
        .react-calendar__tile--active:not(.disable) {
          abbr {
            padding: 3px;
          }
        }
      }
    }
  }
  .freeSlotsContainer {
    .fsContent {
      .freeSlotBox {
        .freeSlotsItem {
          padding: 4px;
        }
      }
    }
  }

  .resetPassword {
    .resetPasswordForm {
      width: 60%;
    }
  }
}

@media (max-width: 395px) {


  .freeSlotsCalendarContainer {
    .freeSlotsCalendar {
      .react-calendar {
        button {
          font-weight: bold;
        }
        .react-calendar__tile {
          abbr {
            font-size: 12px;
          }
        }
      }
    }
  }
  .freeSlotsContainer {
    .fsContent {
      .freeSlotBox {
        .freeSlotsItem {
          font-size: 12px;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .home {
    .homeBanner {
      padding-top: 20px;
    }

    h1 {
      font-size: 30px;
      line-height: 30px;

      &.homeBannerBigFont {
        font-size: 35px;
        line-height: 35px;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .home {
    .form {
      input {
        font-size: 1.3em;
        line-height: 20px;
      }
    }
    .locationBtnContainer {
      .btlLocation {
        width: 40px;
        height: 40px;
        background-position: 10px;
        padding: 8px;
      }
    }

  }
  .backButton {
   left: 0;
  }
}
