.footer {
  box-shadow: 0px -3px 10px #3D8DE233;
  background-color: #fff;
  position: relative;
  padding: 0;
  margin:0;
  padding:0;
  height:80px;

  .footerContainer{
    padding: 45px 0 25px 0;
  }

  .rgba-white-light {border: 1px dotted white;}
  .logo {
    font-size:12px;
    img {
      height:auto;
      margin:15px 0px;
      width: 45px;
    }
    // padding: 0 20px;
    // width: 150px;
    // img {
    //   background-color: $blue;
    //   width: 150px;
    //   padding: 0 20px;
    //   margin-top: 15px;
    // }
  }

  .appButtons {
    // display: flex;
    // flex-direction: column;
    // margin-top: 3px;
    // margin-left: auto;
    // a {
    //   height: 25px;
    //   margin-left: auto;
    //   padding: 2px;
    //   img {
    //     height: 100%;
    //   }
    // }
  }

  a {
    color: #21436C;
    opacity: 80%;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      color: #6F54EC;
    }
  }
}