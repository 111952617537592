
.gradient {
  background-image: linear-gradient(to bottom, rgba(245,246,248, 0) 0%, rgba(245,246,248, 0.2) 70%, rgba(245,246,248, 1) 100%) , url('../common/images/header_image.png');
  //background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  //background-position-y: 100%;
  display: flex;
  flex-flow: column;
  //height: 100%;
  height: 1017px;

  & > .container {
    flex-grow: 1;
  }
}

.content {
  background-color: #F5F6F8;
  height: 100vh;
}
.btn-black {
  background-color: $black;
  width: 100px;
  height: 40px;
  padding: 10px;
  background-size: 90%;
  background-position: center;
  margin-right: 10px
}
.btn-google-play {
  background-image: url('../common/images/google-play-badge.png');
  background-repeat: no-repeat;
}

.btn-app-store{
  background-image: url('../common/images/app-store-badge.png');
  background-repeat: no-repeat;
}
.notFound{
  margin-top: 200px;
  h3 {
    font-size: 60px;
  }
  a{
    font-size: 30px;
  }
}
form {
  input[type=text], textarea, input[type=password], input[type=tel], input[type=email], select {
    position: relative;
    letter-spacing: .01rem;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 5px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    height: 35px;
    width: 100%;
    outline: none;
    font-family: Helvetica, sans-serif !important;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    color: #666;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $lightGrey;

    &:focus {
      border-color: $lightBlue !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $lightBlue;
    }

    &::placeholder {
      color: $placeholderGrey;
    }
  }

  select {
    height: 35px;
    color: $blue;

    &.placeholder {
      color: $placeholderGrey;
    }

    option:first-of-type {
      color: $placeholderGrey;
    }
    option:not(:first-of-type) {
      color: $blue;
    }
    option[value=''] {
    color: $placeholderGrey;
  }
  }
  textarea {
    height: auto;
  }

  .remaining {
    position: absolute;
    opacity: 0.5;
    color: rgb(153, 153, 153);
    font-size: 11px;
    right: 30px;
    bottom: 10px;
  }
}

.alert_container {
  width: 550px;
  margin-left: -225px;
}

.button {
  width: 140px;
  height: 45px;
  min-width: 110px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 2.5px;
  font-weight: bold;
  color: white;
  background-color: #5BDEE5!important;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-cal
{
    color: white;
    background-color: #2a8ae5;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.btn-gray
{
  min-width: 110px;
  border-radius: 5px;
  padding:10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

// https://codepen.io/seme332/pen/reJOwo
.button:hover,.btn-cal:hover,.btn-gray:hover  {
  background-color: #a441dd!important;
  box-shadow: 0px 5px 10px rgba(121, 43, 223, 0.4);
  color: #fff!important;
  transform: translateY(-1px);
}
.btn-gray:hover{
  // border-color: #6c757d;
  background-color: #6c757d!important;
  box-shadow: 0px 5px 10px rgba(106, 105, 107, 0.4);
}


.modal-content{
  box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.62), 0 6px 20px 0 rgba(100, 100, 100, 0.59);
  h3,h4{
    padding:5px;
    text-align: center;
  }
  form {
    padding:15px 25px;
  }
}
.textGray {
  color: rgba(33, 67, 108, 1);
  h3 {
    opacity: 80%;
  }
}