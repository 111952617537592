.staticPages {
  background-color: $white;
  margin: 60px 0;
  border-radius: 10px;
  padding: 20px;
  color: $black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &.grey {
    color: $grey;
  }

  p {
    margin-top: 10px;
    text-align: justify;
  }

  h3 {
    margin-top: 20px;
  }
  ul {
    text-align: justify;
    padding: 10px;
    li {
      list-style: inside;
    }
  }

  .contactForm {
    .form-group {
      color: $grey;
      input, select {
        position: relative;
        letter-spacing: .01rem;
        z-index: 0;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 5px;
        margin-left: 0;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 5px;
        height: 45px;
        padding: 10px;
        width: 100%;
        outline: none;
      }
    }

    .btn-dark-blue {
      background-color: #226C9E;
    }
  }
}