@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'config';
@import 'main';
@import 'responsive';

html {
    background-color: #F5F6F8;
}

body {
    margin: 0;
    padding: 0;
    color: $white;
    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    p, ul {
        margin:0;
        padding:0;
    }
}
