.confirmedAppointment {
  background-color: $white;
  border-radius: 10px;
  padding: 25px 25px 0 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .confirmedContent {
    color: $grey;
  }
  .top {
    padding-top: 5px;
    color: $grey;
    border-bottom: 1px dotted #ccc;
  }
  .confirmedContent {
    align-items: center;
    display: flex;
    span {
      color: $green;
      font-size: 24px;
    }
    img {
      margin-right: 20px;
      width: 42px;
    }
  }
  .bottom {
    color:#888;
    div {
      display: inline-block;
      width: 50%;

      &.text-left {
        a {
          float: left;
        }
      }
      &.text-right {
        a {
          float: right;
        }
      }
    }
    border-top: 1px dotted $grey;
    .btn-light-grey {
      background-color: $lightGrey;
      color: $grey;
      border-color: $lightGrey;
    }
    .btn-dark-blue {
      background-color: #226C9E;
    }
  }
  .add-to-calendar {
    li {
      list-style: none;
      display: inline-block;
      margin: 19px;
      padding:10px;
      font-weight: bold;
      font-size:18px;

      .outlookcom-link {
        background-image: url('../../common/images/outlook.png');
      }
      a {
        width: 80px;
        height: 80px;
        float: left;
        background-size: 80px 80px;
        &:hover{
          text-decoration: none;
        }
      }

      .apple-link {
        background-image: url('../../common/images/ical.png');
      }

      .google-link {
        background-image: url('../../common/images/google-calendar.png');
      }
    }
  }
}

.text-danger > span {
  color: #dc3545 !important;
}