.genderSwitchContainer {
  height: 43px;
  display: flex;
  align-items: center;
  width: 125px;
  float: right;
  flex-direction: row-reverse;

  .labelGender-M-1, .labelGender-F-3 {
    span {
      color: $blue;
    }
  }

  > div {
    display: inline-flex;
    font-size: 20px;
    line-height: 50px;
  }
}

.genderSwitch {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 117px;
  height: 43px;
  border-radius: 100px;
  position: relative;

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 43px !important;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0 2px !important;
    background: url('../../common/images/slider_bg_1sel.png') no-repeat !important;
    border: none !important;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 35px;
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
  }
}

.genderSwitch-1 {
  .slider {
    background: url('../../common/images/slider_bg_0sel.png') no-repeat !important;
  }
}

.genderSwitch-3 {
  .slider {
    background: url('../../common/images/slider_bg_2sel.png') no-repeat !important;
  }
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}
