.header {
  background-color: #fff;
  box-shadow: 0px 3px 10px #3D8DE233;;
  height: 80px;
  .logo {
      color: $white;
  }
  .languageSwitcher {
    z-index: 1001;
    justify-content: right;
    &.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff;
    }
    .navbar-nav {
      margin-left: 20px;
      a {
        opacity: 80%;
      }
      .dropdown-menu {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        margin-right: -10px;
      }
    }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .nav-link {
      color: #21436C;
      &:hover {
        color: #6F54EC;
      }

      &::after {
        color: #6F54EC;
      }
    }
  }

  .separator {
    &::after {
      content: "|";
      color: #707070;
      opacity: 30%;
      margin-left:20px;
    }
  }
  a {
    color: #21436C;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      color: #6F54EC;
    }
  }
}