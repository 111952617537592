.resourceTypeItem {
  background-color: #fff;
  border-radius: 20px;
  padding: 27px;
  min-height: 270px;
  margin: 0 auto 25px;
  max-width: 270px;
  min-width: 270px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  .imageContainer {
    height: 140px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }

  img {
    height: 90px;
  }
  h3 {
    color: $blue;
    font-size: 20px;
  }
  .smallText {
    color: $grey;
    font-size: 12px;
  }
  hr {
    border-top: dotted 1px $grey;
    margin: 0 auto;
    width: 70%;
  }
}

.resourceTypeAppointment:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.59);
}

.resourceTypeAppointment {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 0 auto 20px;
  max-width: 350px;
  min-width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  a {
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .appointmentDetails {
    :hover{
      text-decoration: underline;
    }
    padding: 0 20px;
    h5 {
      color: $blue;
      border-bottom: 1px dotted $grey;
      font-size: 16px;
      // font-style: italic;
      padding-bottom: 10px;
      font-weight: bold;
    }

    .appointmentDuration{
      color: $blue;
      text-align: left;
      // font-style: italic;
      // font-size: 14px;
    }

    .smallText {
      color: $grey;
      font-size: 14px;
      text-align: justify;
    }
  }

  img {
    height: 50px;
  }
}


.backButton, .backButtonAppointers {
  position: absolute;
  top: 10px;
  left: 90px;
  border: solid rgba(33, 67, 108, 0.8);
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 999;
  border-radius: 3px;
}

.layer1, .layer2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  .layer1-item {
    margin: 0 10px;
    justify-content: stretch;
    display: flex;
  }

  .layer2-item {
    margin: 0 10px;
    flex: 1 0 300px; /* explanation below */
    max-width: 300px;

    justify-content: stretch;
    display: flex;
  }
}